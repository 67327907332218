// Block Theme
@import "../../css/theme.min.css";

// Theme Dependencies
@import "../../libs/swiper/swiper-bundle.min.css";
@import "../../libs/simplebar/dist/simplebar.min.css";
@import "../../libs/bootstrap-icons/font/bootstrap-icons.min.css";
@import "../../libs/glightbox/dist/css/glightbox.min.css";
@import "../../libs/scrollcue/scrollCue.css";
@import "../../fonts/css/boxicons.min.css";

// SCSS that is non-theme related should go here:
// ./_sass/index.scss
@import "index";
