// custom, not theme-related CSS goes here

@import './cookieconsent';

.navbar-brand {
  // disable weird invert filter of theme
  filter: none !important;

  img {
    height: 36px;
  }
}

.footer-logo {
  max-height: 100px;
}

// price animation fix (overwrites theme)

.price-text {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

section[id], h1[id] {
  scroll-margin: 80px;
}
